import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserClaim } from '../models/cookie-auth/user-claim'
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  private baseUrl: string;
  private selfUrl: string;
  public userId: string;
  public clientNameSpace: string;
  private userClaims: UserClaim[];
  public $userClaims: BehaviorSubject<UserClaim[]> = new BehaviorSubject<UserClaim[]>(null);

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.$configuration.subscribe(config => {
      if(config != null){
        this.baseUrl = config.baseUrl;
        this.selfUrl = window.location.href;
        this.loadUserData();
      }
    });
  }

  public signIn() {
    window.location.href = `${this.baseUrl}/api/auth/signin?returnUrl=${this.selfUrl}`;
  }

  public signOut() {
    window.location.href = `${this.baseUrl}/api/auth/signout?returnUrl=${this.selfUrl}`;
  }

  public async loadUserData() {
    if(this.userClaims == null || this.userClaims.length == 0){
      await this.http.get<UserClaim[]>(`${this.baseUrl}/api/auth/user`).toPromise().then(claims => {
        this.userClaims = claims;
        this.$userClaims.next(this.userClaims);
      }).catch(_ => {
        this.signIn();
      });
    }
  }

  public isSignedIn(): boolean {
    return this.userClaims != null && this.userClaims.length > 0;
  }
}