import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth-service.service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private user;
  public $user: BehaviorSubject<any>;
  private clientNameSpace: string;
  private userId: string;
  private baseUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService, private authService: AuthService) {
    this.$user = new BehaviorSubject<any>(null);
    this.configService.$configuration.subscribe(config => {
      if (config != null) {
        this.baseUrl = config.baseUrl;
      }
    });
    this.authService.$userClaims.subscribe(claims => {
      if (claims != null && claims.length > 0) {
        var clientNameSpace = claims.find(claim => claim.type == "df.ns").value;
        var userId = claims.find(claim => claim.type == "df.userid").value;
        this.clientNameSpace = clientNameSpace ? clientNameSpace : "UNKNOWN_NOTFOUND";
        this.userId = userId ? userId : "UNKNOWN_NOTFOUND";
        this.loadUser();
      }
    });
  }
  // Load authenticated user data from okta
  private async loadUser() {
    var url = `${this.baseUrl}/api/${this.clientNameSpace}/users/${this.userId}`;
    await this.http.get<any>(url).toPromise()
      .then(user => {
        this.user = user;
        this.$user.next(this.user);
      }).catch(e => { 
        console.error(e);
      });
  }
}