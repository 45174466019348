/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { Toast } from 'bootstrap';
import { fromEvent } from 'rxjs';
import { EventTypes } from 'src/app/models/event-types';
import { ToastEvent } from 'src/app/models/toast-event';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
})
export class ToastComponent implements OnInit {
  currentToast: ToastEvent;
  showToaster: boolean = false;
  @Output() disposeEvent = new EventEmitter();

  @ViewChild('toastElement', { static: true })
  toastEl!: ElementRef;

  @Input()
  type!: EventTypes;

  @Input()
  title!: string;

  @Input()
  message!: string;
constructor(
  private toastService: ToastService,
){

}

  ngOnInit() {
    this.subscribeToToasts();
  }

  subscribeToToasts() {
    this.toastService.toastEvents.subscribe((toasts) => {
      const toastMessage: ToastEvent = {
        type: toasts.type,
        title: toasts.title,
        message: toasts.message,
      };
      this.currentToast= toastMessage;
      if(this.currentToast.type != "error"){      setTimeout(() => {
        this.currentToast = null;
      }, 1500)}else{
        setTimeout(() => {
          this.currentToast = null;
        }, 3000)
      }

    });
  }

}