import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {


  public $dataStudioDisplay = new BehaviorSubject<string>("logo"); //"logo"/"logo"/"testWindow" (there are others, but this is prod/test/dev)
  public $viewMode = new BehaviorSubject<string>("navigator"); //"data studio"/"navigator"
  public $selectedIntegration = new BehaviorSubject<string>(null);
  public $dataStudioTitle = new BehaviorSubject<string>("");
  public $primaryButtonText = new BehaviorSubject<string>("");
  public $selectedSpanText = new BehaviorSubject<string>("");
  public $listOption = new BehaviorSubject<any>("");
  public $selectSideNavWidth = new BehaviorSubject<string>("");
  
  // For run-data(data validation) Component
  public $selectedDataValidationSolution = new BehaviorSubject<string>("");
  public $selectedDataValidationDate = new BehaviorSubject<string>("");
  public $selectedDataValidationName = new BehaviorSubject<string>("");
  public $dataValidationDrawerOpen = new BehaviorSubject<boolean>(false)

  //For action Component
  public $selectedActionSolution = new BehaviorSubject<string>("");
  public $selectedActionDate = new BehaviorSubject<string>("");

  //For Table Component
  public $selectedTableId = new BehaviorSubject<string>(null);
  public $tableGreyOut = new BehaviorSubject<boolean>(false);

  //For data-lake(file downloads) Component
  public $selectedDataLakeSolution = new BehaviorSubject<string>("");
  public $selectedDataLakeDate = new BehaviorSubject<string>("");
  public $dataLakeGreyOut = new BehaviorSubject<boolean>(false)

  //For run-log Component
  public $selectedRunLogSolution = new BehaviorSubject<string>("");
  public $selectedRunLogDate = new BehaviorSubject<string>("");
  public $selectedRunLogDrawer = new BehaviorSubject<boolean>(false);

  //For error-log Component
  public $selectedErrorLogSolution = new BehaviorSubject<string>("");
  public $selectedErrorLogDate = new BehaviorSubject<string>("");
  public $selectedErrorLogDrawer = new BehaviorSubject<boolean>(false);


  //For Data Studio GrayOut
  public $selectedGrayOut = new BehaviorSubject<boolean>(false);

  //grayout screen at the beginning
  public $sideNavGrayOut = new BehaviorSubject<boolean>(false);



  //============= Start Code


  constructor() { }

  //broadcasts to the data studio which component to render
  public setDataStudioDisplay(display: string) {
    this.$dataStudioDisplay.next(display);
  }

  //broadcasts to the dashboard which layout to use
  public setViewMode(viewMode: string) {
    this.$viewMode.next(viewMode);
  }

  //broadcasts to many components, which folders in the data lake should be used.
  public setIntegration(integrationName: string){
    this.$selectedIntegration.next(integrationName);
  }

  //sets title in data studio
  public setDataStudioTitle(displayTitle: string){
    this.$dataStudioTitle.next(displayTitle);
  }

  public setPrimaryButtonText(title: string){
    this.$primaryButtonText.next(title);
  }

  public setSelectedSpanText(title: string){
    this.$selectedSpanText.next(title);
  }

  //sets run/logs/errors/date lake list option
  public setListOption(displayOption){
    this.$listOption.next(displayOption);
  }

  // Tables
  public selectTable(tableId:string){
    this.setPrimaryButtonText("Tables");
    this.setDataStudioTitle("")
    this.$selectedTableId.next(tableId);
  }
  public toggleTableGreyOut(greyOut:boolean){
    this.$tableGreyOut.next(greyOut);
  }

  // Data Validation
  public selectDataValidationSolution(solution:string){
    this.setPrimaryButtonText("Data Validations");
    this.setSelectedSpanText(solution);
    this.setDataStudioTitle("");
    this.$selectedDataValidationSolution.next(solution);
  }

  public selectDataValidationDate(date:string){
    this.$selectedDataValidationDate.next(date);
  }

  public selectDataValidationName(name:string){
    this.$selectedDataValidationName.next(name);
  }

  // Data Validation drawer checker
  public dataValidationDrawerOpen(boolean){
    this.$dataValidationDrawerOpen.next(boolean)
  }

  // Data Lake
  public selectDataLakeSolution(solution:string){
    this.setPrimaryButtonText("Outputs");
    this.setSelectedSpanText(solution);
    this.setDataStudioTitle("");
    this.$selectedDataLakeSolution.next(solution);
  }

  public selectDataLakeDate(date:string){
    this.$selectedDataLakeDate.next(date);
  }

  // Action
  public selectActionSolution(solution:string){
    this.setPrimaryButtonText("Actions");
    this.setDataStudioTitle("");
    this.setSelectedSpanText(solution);
    this.$selectedActionSolution.next(solution);
  }

  public selectActionDate(date:string){
    this.$selectedActionDate.next(date);
  }

  // Run Log
  public selectRunLogSolution(solution:string){
    this.setPrimaryButtonText("Process Logs");
    this.setSelectedSpanText(solution);
    this.$selectedRunLogSolution.next(solution);
  }

  public selectRunLogDate(date:string){
    this.$selectedRunLogDate.next(date);
  }

  public runLogDrawer(open:boolean){
    this.$selectedRunLogDrawer.next(open)
  }


  // Error Log
  public selectErrorLogSolution(solution:string){
    this.setPrimaryButtonText("Errors");
    this.setSelectedSpanText(solution);
    this.$selectedErrorLogSolution.next(solution);
  }

  public selectErrorLogDate(date:string){
    this.$selectedErrorLogDate.next(date);
  }

  public errorLogDrawer(open:boolean){
    this.$selectedErrorLogDrawer.next(open)
  }

  //toggle dataStudio grayout
  public toggleGrayOut(grayOut:boolean){
    this.$selectedGrayOut.next(grayOut);
  }

  //grayout sidenav
  public toggleSideNavGrayOut(grayOut:boolean){
    this.$sideNavGrayOut.next(grayOut)
  }


}
