<div class="container">
    <div [id]="tableName" class="tabulator-container"></div>
    <div class="exports">
        <div>CSV: </div>
        <img class="csv-download-icon" src="../../assets/images/icons/FilldownloadFile.svg" (click)="downloadCsv()"
            alt="download as csv file" >

        <div>JSON: </div>
        <img class="json-download-icon"  src="../../assets/images/icons/FilldownloadFile.svg" (click)="downloadJson()"
            alt="download as json file">
    </div>
</div>