<mat-toolbar color="primary" class="toolbar">
    <button mat-icon-button class="fuze-icon">
        <img class="logo" src=" ../../assets/images/Ceridian_white.svg">
    </button>

    <div *ngIf="durableFunctionEnvironment == 'DEV'">
        <img class="logo" src=" ../../../assets/images/Function-Apps.svg">
        <img class="logo" src=" ../../../assets/images/dev.svg">
    </div>
    <div *ngIf="durableFunctionEnvironment == 'TEST'">
        <img class="logo" src=" ../../../assets/images/Function-Apps.svg">
        <img class="logo" src=" ../../../assets/images/test.svg">
    </div>

    <div *ngIf="datalakeEnvironment == 'DEV'">
        <img class="logo" src=" ../../../assets/images/Storage-Accounts.svg">
        <img class="logo" src=" ../../../assets/images/dev.svg">
    </div>
    <div *ngIf="datalakeEnvironment == 'TEST'">
        <img class="logo" src=" ../../../assets/images/Storage-Accounts.svg">
        <img class="logo" src=" ../../../assets/images/test.svg">
    </div>

    <app-integration-selector-toolbar class="integration-selector" *ngIf="showPrimaryButtons"></app-integration-selector-toolbar>
    <span class="toolbar-spacer-middle" *ngIf="!showPrimaryButtons"></span>
    <div class="buttons">
        <button *ngIf="showPrimaryButtons" matTooltip="Action" matTooltipPosition="below" mat-icon-button class="nav-icon" (click)="setDisplay('action')">
            <mat-icon [ngClass]="{'material-icons-outlined': display !== 'action','icon-glow': display === 'action'}">play_arrow</mat-icon>
        </button>
        <button *ngIf="showPrimaryButtons" matTooltip="Tables" matTooltipPosition="below" mat-icon-button class="nav-icon" (click)="setDisplay('tables')">
            <mat-icon [ngClass]="{'material-icons-outlined': display !== 'tables','icon-glow': display === 'tables'}">grid_on</mat-icon>
        </button>
        <button *ngIf="showPrimaryButtons" matTooltip="Scheduling" matTooltipPosition="below" mat-icon-button class="nav-icon" (click)="setDisplay('logo')">
            <mat-icon [ngClass]="{'material-icons-outlined': true,'icon-glow': false}">access_time</mat-icon>
        </button>
        <button *ngIf="showPrimaryButtons" matTooltip="BI" matTooltipPosition="below" mat-icon-button class="nav-icon" (click)="setDisplay('logo')">
            <mat-icon [ngClass]="{'material-icons-outlined': true,'icon-glow': false}">timeline</mat-icon>
        </button>
        <button matTooltip="Log Off" matTooltipPosition="below" mat-icon-button class="nav-icon" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
        </button>
    </div>
</mat-toolbar>