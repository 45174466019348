import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service.service';
import { ConfigService } from 'src/app/services/config.service';
import { DisplayService } from 'src/app/services/display.service';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit{
  showPrimaryButtons: boolean = false;
  logoutUri: string;
  display: string;
  durableFunctionEnvironment: string = "PROD";
  datalakeEnvironment: string = "PROD";
  private baseUrl: string;


  constructor( private authService:AuthService, private displayService: DisplayService, private environmentService: EnvironmentService ) {
    this.displayService.toggleSideNavGrayOut(true);
  }
  
  // checks what the display mode is, if the display mode == "data studio", then buttons that would be hidden
  // by the data-studio full screen will be rendered in the overhead toolbar.
  async ngOnInit(): Promise<void> {
    this.displayService.$viewMode.subscribe(viewMode => {
      if(viewMode == "navigator"){
        this.showPrimaryButtons = false;
      }
      else {
        this.showPrimaryButtons = true;
      }
    });
    this.environmentService.$durableFunctionEnvironment.subscribe(environment => {
      this.durableFunctionEnvironment = environment;
    });
    this.environmentService.$datalakeEnvironment.subscribe(environment => {
      this.datalakeEnvironment = environment;
    });
    this.displayService.$dataStudioDisplay.subscribe(display => {
      this.display = display;
    });
  }

  // ends the users okta session
  logout() {
    this.authService.signOut();
  }

  // communicates to the display service when the component rendered in the data studio needs to be changed.
  setDisplay(display: string){
    this.displayService.setDataStudioDisplay(display);
  }
}
