import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TableService } from 'src/app/services/table.service';


@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})

export class TablesComponent implements AfterViewInit {
  private $tableInfo = new BehaviorSubject<any[]>(null);
  public _tableInfo = this.$tableInfo.asObservable();

  constructor(private tableService: TableService) {
  }

  //wait for tables to be loaded into the table service, then load tables into component 
  ngAfterViewInit() {
    this.tableService.$tables.subscribe(tables => {
      if (tables != null){
        var tableInfo: any[] = [];
        tables.forEach(table => {
          tableInfo = tableInfo.concat({id : table.id, alias : table.alias});
        });
        this.$tableInfo.next(null)
        this.$tableInfo.next(tableInfo);
      }
    });
  }
}
