<!-- <div *ngIf="showToaster"
  #toastElement
  class="toast fade toast-width mt-2"
  [ngClass]="type"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
<div *ngIf="currentToast">

</div>

</div> -->
<div *ngIf="currentToast"  [ngClass]="currentToast.type" class="toast">
  <div>{{currentToast.title}}</div>
  <div>{{currentToast.message}}</div>
</div>
