import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public $durableFunctionEnvironment = new BehaviorSubject<string>("PROD");
  public $datalakeEnvironment = new BehaviorSubject("PROD");

  public setDatalakeEnvironment(environment: string) {
    switch(environment) {
      case "PROD":
        this.$datalakeEnvironment.next(environment);
        break;
      case "TEST":
        this.$datalakeEnvironment.next(environment);
        break;
      case "DEV":
        this.$datalakeEnvironment.next(environment);
        break;
    }
  }

  public setDurableFunctionEnvironment(environment: string) {
    switch(environment) {
      case "PROD":
        this.$durableFunctionEnvironment.next(environment);
        break;
      case "TEST":
        this.$durableFunctionEnvironment.next(environment);
        break;
      case "DEV":
        this.$durableFunctionEnvironment.next(environment);
        break;
    }
  }
}
