import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { BehaviorSubject } from 'rxjs';
import { IntegrationDetails } from 'src/app/models/integration-details';
import { DisplayService } from 'src/app/services/display.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-integration-selector-toolbar',
  templateUrl: './integration-selector-toolbar.component.html',
  styleUrls: ['./integration-selector-toolbar.component.css']
})

export class IntegrationSelectorToolbarComponent implements OnInit {

  public user: any;
  private $connections = new BehaviorSubject<IntegrationDetails[]>([]);
  public connections = this.$connections.asObservable();
  public selectedIndex: number;
  @ViewChild('overheadTabs', {static: false}) tabs;

  constructor(private tenantService: TenantService,
    private userService: UserService,
    private displayService: DisplayService) { }

  // wait for user data to load
  // wait for tenant data to load
  // load integration/connection data from the tenant data.
  ngOnInit(): void {
    this.userService.$user.subscribe(user => {
      if (user != null) {
        this.user = user;
        this.tenantService.$tenant.subscribe(t => {
          if (t != null) {
            this.$connections.next(t.integrations.map(i => i));
            this.displayService.$selectedIntegration.subscribe(selectIntegration => {
              for (var i = 0; i < this.$connections.value.length; i++) {
                if (this.$connections.value[i].name == this.displayService.$selectedIntegration.value) {
                  this.selectedIndex = i;
                }
              }
            });
            this.displayService.$viewMode.subscribe(_ => {
              this.tabs.realignInkBar();
            });
          }
        });
      }
    });
  }

  // get index of selected tab, then call select integration for connections[index]
  tabClick(event: MatTabChangeEvent) {
    this.selectIntegration(this.$connections.value[event.index].name);
  }

  // communicate to display service which integration the user has selected.
  selectIntegration(integrationName: string) {
    this.displayService.setIntegration(integrationName);
  }
}