<div class="container">
    <div [id]=tableId class="tabulator-container"></div>
    <div class="table-operations">
        <div *ngIf="allowEdit" class="row-operations">
            <img  class="add-row-icon" src="../../assets/images/icons/Filladd.svg"
                matTooltip="Add New Record" mat-stroked-button matTooltipPosition="above" (click)="addRow()">
            <img  class="copy-row-icon" src="../../assets/images/icons/Fillcopy.svg"
                matTooltip="Clone Selected Records" mat-stroked-button matTooltipPosition="above"
                (click)="duplicateSelectedRows()">
            <img  class="delete-row-icon" src="../../assets/images/icons/FilllargeX.svg"
                matTooltip="Delete Selected Records" mat-stroked-button matTooltipPosition="above"
                (click)="deleteSelectedRows()">
            <img class="undelete-row-icon" src="../../assets/images/icons/Fillundo.svg"
                matTooltip="Undelete Selected Records" mat-stroked-button matTooltipPosition="above"
                (click)="undeleteSelectedRows()">
            <img src="../../assets/images/icons/Fillsave.svg" 
                class="material-icons-outlined save-table-icon" matTooltip="Save Changes" mat-stroked-button
                matTooltipPosition="above" (click)="saveTableChanges()">
        </div>
        <div class="row-filters" *ngIf="allowEdit">
            <button matTooltip="{{showOriginalToolTipPrefix}} Original Records" mat-stroked-button
                matTooltipPosition="above" [ngClass]="{'original enabled': showOriginal, 'disabled': !showOriginal}"
                (click)="toggleOriginalRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button  matTooltip="{{showChangedToolTipPrefix}} Edited Original Records"
                mat-stroked-button matTooltipPosition="above"
                [ngClass]="{'changed enabled': showChanged, 'disabled': !showChanged}"
                (click)="toggleEditedOriginalRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button  matTooltip="{{showNewToolTipPrefix}} New Records" mat-stroked-button
                matTooltipPosition="above" [ngClass]="{'new enabled': showNew, 'disabled': !showNew}"
                (click)="toggleNewRecordsFilter()">
                <img *ngIf="addRowShow" src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button  matTooltip="{{showDeletedToolTipPrefix}} Deleted Records"
                mat-stroked-button matTooltipPosition="above"
                [ngClass]="{'deleted enabled': showDeleted, 'disabled': !showDeleted}"
                (click)="toggleDeletedRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button matTooltip="{{showNewDeletedToolTipPrefix}} Deleted New Records"
                mat-stroked-button matTooltipPosition="above"
                [ngClass]="{'new-deleted enabled': showNewDeleted, 'disabled': !showNewDeleted}"
                (click)="toggleDeletedNewRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
        </div>

        <div class="exports">
            <div>CSV: </div>
            <img class="csv-download-icon" src="../../assets/images/icons/FilldownloadFile.svg" (click)="downloadCsv()"
                alt="download as csv file">

            <div>JSON: </div>
            <img class="json-download-icon" src="../../assets/images/icons/FilldownloadFile.svg"
                (click)="downloadJson()" alt="download as json file">

            <!-- <button class="import-cvs-button" (click)="openModal('custom-modal-1')">Import CSV</button> -->
            <!-- <jw-modal id="custom-modal-1">
                <app-drag-n-drop tableId={{tableInfo.id}}></app-drag-n-drop>
                <button (click)="closeModal('custom-modal-1')">close</button>
                <button>next</button>
            </jw-modal> -->
        </div>
    </div>

</div>