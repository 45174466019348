import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastEvent } from 'src/app/models/toast-event';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit {
  // public currentToasts = new BehaviorSubject<ToastEvent[]>([]);
  currentToasts: ToastEvent[] = [];
  showToaster: boolean = false;

  constructor(private toastService: ToastService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscribeToToasts();
  }

  subscribeToToasts() {
    this.toastService.toastEvents.subscribe((toasts) => {
      const currentToast: ToastEvent = {
        type: toasts.type,
        title: toasts.title,
        message: toasts.message,
      };
      
      this.currentToasts.push(currentToast);
      this.showToaster = true;
      this.cdr.detectChanges();
    });
  }

  dispose(index: number) {
    index;
    this.currentToasts.splice(index, 1);
    this.cdr.detectChanges();
  }

  
  hide() {
    // // this.toast.dispose();
    // this.disposeEvent.emit();
    
  }
  changeToaster(){
    // this.toastService.$toastDisplay.next(false);
    this.showToaster = false ;
  }
}