<div class="admin-container">
    <div class="sub-admin-left-container">
        <div class="users">
            <div class="users-buttons">
                <button (click)="save()">Save</button>
            </div>
            <div class="users-list-container">
                <p class="users-sub-header">Users</p>
                <div class="users-search-container">
                <input type="test" placeholder="Search...." [(ngModel)]="searchStringUser" (input)="filterUserList()">
                </div>
                <div class="seperator-container">
                    <div class="seperator"></div>
                </div>
                
                <div class="users-list-item" *ngFor="let user of filteredUsers; let i = index" [class.selected]="user===selectedUser"  [ngClass]="{'gray-background': shouldGrayBackground(i)}" (click)="selectUser(user)">
                    {{user.firstName}} {{user.lastName}}
                </div>
            </div>
        </div>
        <div class="roles">
            <!-- <p class="roles-sub-header">Roles</p> -->
            <div class="roles-title-section">
                <!-- <p class="roles-sub-header" *ngIf="selectedUser">{{selectedUser.firstName}}</p> -->
                <span class="roles-sub-header">Roles</span>
                <button (click)="addRole()">Add Role</button>
                <button (click)="editRole()">Edit Role</button>
            </div>
           
            <input class="roles-search" type="text" placeholder="Search...." [(ngModel)]="searchStringRole" (input)="filterRoleList()">
            <div class="column-headers">
                <span class="left-align-item">
                    Role Name
                </span>
                <span class="center-item">
                    Enabled
                </span>
                <span class="center-item">
                    Expiration Date
                </span>
            </div>
            <div class="roles-list-item" *ngFor="let role of filteredRoles; let i = index" [ngClass]="{'gray-background': shouldGrayBackground(i), 'selected': role===selectedRole,'selected-role': role===selectedRole && this.editIndex !=null && this.enableEditing===true}"   (click)="selectRole(role)">
                <input [ngClass]="{'read-input-only': editIndex !== role.id,'': editIndex === role.id}"
                    [(ngModel)]="role.name"
                    (click)="toggleEditing(role)">
                    <button class="update-button "*ngIf="editIndex === role.id && this.enableEditing==true" (click)="updateEdit()">Update</button>
                <input class="center-item checkbox" type="checkbox" id="enable" [disabled]="selectedUser == null" [checked]="role.enable" (click)="toggleUserRole(selectedUser, role)">
                <input class="center-item expirationDate" [ngClass]="{'selected-role-expiration-date': role===selectedRole && this.editIndex !=null && this.enableEditing===true}"   type="date" id="expirationDate" [ngModel]="role.expirationDate| date:'yyyy-MM-dd'" (ngModelChange)="role.expirationDate=$event" (change)="toggleUserExpirationDate(selectedUser, role)">
            </div>
        </div>
    </div>
    <!-- can be used to hide container when role is not clicked -->
    <!-- <div class="sub-admin-right-container" *ngIf="selectedRole"> -->
    <div class="sub-admin-right-container">
        <div class="right-container-header">
            <span class="permissions-button" [ngClass]="{'highlight-button': selectedTab == 'Permissions'}" (click)="setTab('Permissions')">
                Permissions
            </span>
            <span class="file-groups-button" [ngClass]="{'highlight-button': selectedTab == 'FileGroups'}" (click)="setTab('FileGroups')">
                File Groups
            </span>
        </div>
        <div class="select-role-text"*ngIf="!selectedRole"> Please select a Role</div>
        <!-- Permissions -->
        <div class="permissions" *ngIf="selectedTab == 'Permissions' && selectedRole != null">
            <!-- Security Admin Permissions -->
                <div class="permissions-header-container">
                    <h1 class="center-item"></h1>
                    <h3 class="center-item permission-name">Admin</h3>
                    <h3 class="center-item"></h3>
                    <h3 class="center-item"></h3>
                </div>
                <div class="permissions-checkbox-container">
                    <h1 class="left-align-item">Security</h1>
                    <input class="center-item checkbox" type="checkbox" [checked]="selectedRole.permissions.securityAdmin" (click)="toggleSecurityAdmin()">
                    <div class="center-item spacers"></div>
                    <div class="center-item spacers"></div>
                </div>     
            <div class="seperator-container">
                <div class="seperator"></div>
            </div>
            <!-- Table Permissions -->
                <div class="permissions-header-container-tables">
                    <h1 class="left-align-item">Tables</h1>
                    <p class="permission-name center-item">Admin</p>
                    <p class="permission-name center-item">Edit</p>
                    <p class="permission-name center-item">Read</p>
                </div>
                <div class="permissions-checkbox-container-tables">
                    <div class="left-align-item permission-name">Full Access</div>
                    <input class="center-item checkbox" type="checkbox" [checked]="tableAdminEnabled()" (click)="toggleTableAdmin()">
                    <div class="center-item spacers"></div>
                    <div class="center-item spacers"></div>
                </div>

                <div class="permissions-checkbox-container-tables" *ngFor="let table of tables; let i = index"  [ngClass]="{'gray-background': shouldGrayBackground(i)}">
                    <div class="left-align-item">{{table.actualName}}</div>
                    <div class="center-item spacers"></div>
                    <input class="center-item checkbox" type="checkbox" [checked]="tablePermissionEnabled(table.name, 'Write')" (click)="toggleTablePermission(table.name, 'Write')">
                    <input class="center-item checkbox" type="checkbox" [checked]="tablePermissionEnabled(table.name, 'Read')" (click)="toggleTablePermission(table.name, 'Read')">
                </div> 
            <div class="seperator-container">
                <div class="seperator"></div>
            </div>

            <!-- Integration Permissions -->
                <div class="permissions-header-container">
                    <h1 class="left-align-item">Integrations</h1>
                    <h3 class="center-item" class="center-item">Admin</h3>
                    <h3 class="center-item" class="center-item">Execute</h3>
                    <h3 class="center-item" class="center-item">Logs</h3>
                    <h3 class="center-item" class="center-item">Data Validation</h3>

                </div>
                <div class="permissions-checkbox-container">
                    <div class="left-align-item">Full Access</div>
                    <input class="left-align-item checkbox" type="checkbox" [checked]="integrationAdminEnabled()" (click)="toggleIntegrationAdmin()">
                    <div class="left-align-item spacers"></div>
                    <div class="left-align-item spacers"></div>
                    <div class="left-align-item spacers"></div>
                </div>

                <div class="permissions-checkbox-container" *ngFor="let integration of integrations; let i = index"  [ngClass]="{'gray-background': shouldGrayBackground(i)}">
                    <div class="left-align-item">{{integration.name}}</div>
                    <div class="center-item spacers"></div>
                    <input class="center-item checkbox" type="checkbox" [checked]="integrationPermissionEnabled(integration.name, 'Process')" (click)="toggleIntegrationPermission(integration.name, 'Process')">
                    <input class="center-item checkbox" type="checkbox" [checked]="integrationPermissionEnabled(integration.name, 'ErrorLog')" (click)="toggleIntegrationPermission(integration.name, 'ErrorLog')">
                    <input class="center-item checkbox" type="checkbox" [checked]="integrationPermissionEnabled(integration.name, 'DataValidation')" (click)="toggleIntegrationPermission(integration.name, 'DataValidation')">
                </div>  
        </div>
        <!-- File Groups -->
        <div class="file-groups" *ngIf="selectedTab == 'FileGroups' && selectedRole != null">

            <!-- Assigned File Groups -->
            <div class="file-groups-container">
                <p class="file-groups-header">Assigned</p>
                <div class="file-groups-container-inner">
                    <input type="text" placeholder="Search...." [(ngModel)]="searchStringAssign" (input)="filterAssignList()">
                    <span *ngFor="let fileGroup of filteredAssignedFiles; let i =index" (click)="toggleFileGroupSelection(fileGroup)">
                        <div [ngClass]="{'gray-background': shouldGrayBackground(i), 'selected': fileGroup.isSelected}">
                            {{fileGroup.name}}
                        </div>
                    </span>
                </div>
            </div>
            <!-- Assignment Buttons -->
            <div class="arrow-container">
                <button (click)="RemoveSelectedFileGroupsFromRole()">
                    <img src="../../assets/images/icons/FillchevronRight.svg">
                </button>
                <button (click)="AssignSelectedFileGroupsToRole()">
                    <img src="../../assets/images/icons/FillchevronLeft.svg">
                </button>
            </div>
            <!-- Available File Groups -->
            <div class="file-groups-container">
                <p class="file-groups-header">Available</p>
                <div class="file-groups-container-inner">
                    <input type="text" placeholder="Search...." [(ngModel)]="searchStringAvaliable" (input)="filterAvailableList()">
                    <span *ngFor="let fileGroup of filteredAvailableFiles; let i =index" (click)="toggleFileGroupSelection(fileGroup)">
                        <!-- <div [ngClass]="{'selected': fileGroup.isSelected, '': !fileGroup.isSelected}" > -->
                        <div [ngClass]="{'gray-background': shouldGrayBackground(i), 'selected': fileGroup.isSelected}">
                            {{fileGroup.name}}
                        </div>
                    </span>
                    <!-- Dropping these components temporarily for demo stuff -->
                    <!-- <div class="bottom-edit-buttons-container">
                        <div class="bottom-edit-buttons">
                            <button (click)="addFile()">add</button>
                            <button (click)="editFile()">edit</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>