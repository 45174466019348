import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Role } from "../models/admin/role";
import { RoleListing } from "../models/admin/roles-listing";
import { UserV2 } from "../models/admin/user-v2";
import { UserListing } from "../models/admin/user-listing";
import { TableListing } from "../models/admin/table-listing";
import { TableService } from "./table.service";
import { TableData } from "../models/table-data";
import { FileGroup } from "../models/admin/file-group";
import { FileGroupListing } from "../models/admin/file-group-listing";
import { TenantService } from "./tenant.service";
import { IntegrationDetails } from "../models/integration-details";
import { Permissions } from "../models/admin/permissions/permissions";
import { FileGroupPermissionListing } from "../models/admin/permissions/file-group-permission-listing";
import { IntergrationPermissionListing } from "../models/admin/permissions/intergration-permission-listing";
import { TablePermissionListing } from "../models/admin/permissions/table-permission-listing";
import { ProjectGroupPermissionListing } from "../models/admin/permissions/project-group-permission-listing";
import { MyAccess } from "../models/my-access";
import { AuthService } from "./auth-service.service";
import { ConfigService } from './config.service';


@Injectable({
    providedIn: 'root'
})

export class AdminService {
    public users: UserV2[] = [];
    roles: Role[] = [];
    fileGroups: FileGroup[] = [];
    tables: TableData[] = [];
    integrations: IntegrationDetails[];
    myAccess: MyAccess = null;
    public baseUrl: string;
    clientNameSpace: string;

    constructor(
        private http: HttpClient,
        private tableService: TableService,
        private tenantService: TenantService,
        private authService: AuthService,
        private configService: ConfigService) {
        this.configService.$configuration.subscribe(config => {
            if(config != null){
                this.baseUrl = config.baseUrl;
            }
        });
        this.authService.$userClaims.subscribe(claims => {
            if (claims != null && claims.length > 0) {
                var clientNameSpace = claims.find(claim => claim.type == "df.ns").value;
                this.clientNameSpace = clientNameSpace ? clientNameSpace : "UNKNOWN_NOTFOUND";
            }
        });
    }
    //Load a list of users

    private async getUsers(): Promise<UserListing> {
        var url = `${this.baseUrl}/api/${this.clientNameSpace}/users`;
        let listing: UserListing;
        await this.http.get<UserListing>(url).toPromise()
            .then(l => { listing = l })
            .catch(e => { console.log(`Error retreiving user listing from url ${url}. Exception:${e}`) });
        return listing;
    }

    public async loadMyAccess() {
        if (this.myAccess == null) {
            var url = `${this.baseUrl}/api/${this.clientNameSpace}/MyAccess`;
            let myAccess: MyAccess;
            await this.http.get<MyAccess>(url).toPromise()
                .then(a => { myAccess = a })
                .catch(e => { console.log(`Error retreiving user access from url ${url}. Exception:${e}`) });
            this.myAccess = myAccess;
        }
    }

    public async getUser(id: string): Promise<UserV2> {
        var url = `${this.baseUrl}/api/${this.clientNameSpace}/users/${id}`;
        let user: UserV2;
        await this.http.get<UserV2>(url).toPromise()
            .then(u => { user = u })
            .catch(e => { console.log(`Error retreiving user from url ${url}. Exception:${e}`) });
        return user;
    }

    private async getRoles(): Promise<RoleListing> {
        var url = `${this.baseUrl}/api/${this.clientNameSpace}/roles`;
        let listing: RoleListing;
        await this.http.get<RoleListing>(url).toPromise()
            .then(l => { listing = l })
            .catch(e => { console.log(`Error retreiving user listing from url ${url}. Exception:${e}`) });
        return listing;
    }

    public async getRole(id): Promise<Role> {
        var url = `${this.baseUrl}/api/${this.clientNameSpace}/roles/${id}`
        let role: Role;
        await this.http.get<Role>(url).toPromise()
            .then(r => { role = r; })
            .catch(e => { console.log(`Error retreiving user listing from url ${url}. Exception:${e}`) });
        return role;
    }

    public async getFileGroups(): Promise<FileGroupListing> {
        var url = `${this.baseUrl}/api/${this.clientNameSpace}/fileGroups`
        let listing: FileGroupListing;
        let userPromise = this.http.get<FileGroupListing>(url).toPromise()
            .then(l => { listing = l })
            .catch(e => { console.log(`Error retreiving user listing from url ${url}. Exception:${e}`) });
        await userPromise;
        return listing;
    }

    public async getTableData(): Promise<TableListing> {
        var url = `${this.baseUrl}/api/${this.clientNameSpace}/TableData/`;
        let listing: TableListing;
        await this.http.get<TableListing>(url).toPromise()
            .then(l => { listing = l })
            .catch(e => { console.log(`Error retreiving user listing from url ${url}. Exception:${e}`) });
        return listing;
    }

    public async loadUsers() {
        this.users = (await this.getUsers()).users;
    }

    public async loadRoles() {
        this.roles = (await this.getRoles()).roles;
    }

    public async loadFileGroups() {
        this.fileGroups = (await this.getFileGroups()).fileGroups;
    }

    public async loadTableData() {
        this.tables = (await this.tableService.getTableDataSets(this.clientNameSpace));
    }

    public async createNewRole(name) {
        var url = `${this.baseUrl}/api/${this.clientNameSpace}/roles`;
        let newRole: Role;
        var postBody: { TenantId: String, Id: string, Name: string, Permissions: Permissions } = {
            TenantId: this.tenantService.tenant.id, Name: name,
            Id: "",
            Permissions: new Permissions
        };
        postBody.Permissions.securityAdmin = false;
        postBody.Permissions.fileGroups = new FileGroupPermissionListing();
        postBody.Permissions.fileGroups.admin = false;
        postBody.Permissions.fileGroups.groups = [];
        postBody.Permissions.tables = new TablePermissionListing();
        postBody.Permissions.tables.admin = false;
        postBody.Permissions.tables.dataSets = [];
        postBody.Permissions.integrationPermissions = new IntergrationPermissionListing()
        postBody.Permissions.integrationPermissions.admin = false;
        postBody.Permissions.integrationPermissions.integrations = [];
        postBody.Permissions.projectGroups = new ProjectGroupPermissionListing()
        postBody.Permissions.projectGroups.admin = false;
        postBody.Permissions.projectGroups.group = [];
        postBody.Permissions.projectGroups.permission = "";
        await this.http.post(url, postBody).toPromise<any>()
            .then(r => { newRole = r; })
            .catch(e => { console.log(`Error creating new role from url ${url}. Exception:${e}`) });
        return newRole;
    }

    public async loadIntegrations() {
        this.integrations = await this.tenantService.tenant.integrations;
    }

    public async saveUpdatedUsers(users: UserV2[]) {
        for (let updatedUser of users) {
            var url = `${this.baseUrl}/api/${this.clientNameSpace}/users/${updatedUser.id}`;
            await this.http.put<UserV2>(url, updatedUser)
                .subscribe({
                    next: data => {
                        updatedUser = data
                    },
                    error: error => {
                        console.log('error', error)
                    }
                }
            );
        }
    }

    public async saveUpdatedRoles(roles: Role[]) {
        for (let role of roles) {
            var url = `${this.baseUrl}/api/${this.clientNameSpace}/roles/${role.id}`;
            await this.http.put<Role>(url, role)
                .subscribe({
                    next: data => {
                        role = data;
                    },
                    error: error => {
                        console.log('error', error)
                    }            
                }
            );
        }
    }
}