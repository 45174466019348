import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { BehaviorSubject } from 'rxjs';
import { IntegrationDetails } from 'src/app/models/integration-details';
import { DisplayService } from 'src/app/services/display.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';

@Component({
  selector: 'app-integration-dashboard',
  templateUrl: './integration-dashboard.component.html',
  styleUrls: ['./integration-dashboard.component.css']
})
export class IntegrationDashboardComponent implements OnInit {

  public user: any;
  private $connections = new BehaviorSubject<IntegrationDetails[]>([]);
  public connections = this.$connections.asObservable();
  public selectedIntegration: string;
  navMode: boolean = true;
  public selectedIndex: number;
  @ViewChild('tabs', {static: false}) tabs;

  constructor(
    private tenantService: TenantService,
    private userService: UserService,
    private displayService: DisplayService
  ) { }

  // wait for user data to load
  // wait for tenant data to load
  // wait for connections to load
  // select first connection
  ngOnInit(): void {
    this.userService.$user.subscribe(user => {
      if (user != null) {
        this.user = user;
        this.tenantService.$tenant.subscribe(t => {
          if (t != null) {
            this.$connections.next(t.integrations
            .sort(function(a,b){
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();
              if(nameA < nameB){
                return -1;
              }
              if(nameA > nameB){
                return 1;
              }
              return 0;
            })
            .sort((a,b) => (a.order - b.order)).map(i => i));
          
            this.displayService.$selectedIntegration.subscribe(selectedIntegration => {
              for (var i = 0; i < this.$connections.value.length; i++) {
                if (this.$connections.value[i].name == selectedIntegration) {
                  this.selectedIndex = i;
                }
              }
            });
            this.displayService.$viewMode.subscribe(_ => {
              this.tabs.realignInkBar();
            });
          }
        });
      }
    });
  }

  // use tab index to get the associated integration name. Calls "selectIntegration"
  tabClick(event: MatTabChangeEvent) {
       this.selectIntegration(this.$connections.value[event.index].name);
  }

  // communicates to displayService when a user has changed their selected integration
  selectIntegration(integrationName: string) {
    this.displayService.setIntegration(integrationName);
  }

  // updates which component that the data studio is rendering
  setDisplay(display: string) {
    this.displayService.setDataStudioDisplay(display);
  }
}
