<div *ngIf="showToaster" class="toaster">
  <div *ngFor="let toast of currentToasts ; index as i">
    <app-toast
      [type]="toast.type"
      [title]="toast.title"
      [message]="toast.message"
      (disposeEvent)="dispose(i)"
    ></app-toast>
  </div>
  <button *ngIf="showToaster" type="button" class="btn-close" aria-label="Close" (click)="changeToaster()"(click)="hide()">Close</button>
</div>