<mat-drawer-container class="container">
    <mat-drawer #drawer (openedChange)="onOpenedChange($event)" class="sidenav" mode="push" opened>
        <p class="drawer-title">Data Validation</p>
        <p class="drawer-subtitle">{{selectedDataValidationSolution}}</p>
        <mat-selection-list [multiple]="false">
            <mat-list-option (click)="selectDataValidationDate(date.utcDate)" (click)="setDataStudioTitle(date.longDate)" (click)="drawer.toggle()" class="date-item" *ngFor="let date of dataServiceDates" [value]="date.longDate">
                {{date.longDate}}
                <mat-divider></mat-divider>
            </mat-list-option>
        </mat-selection-list>
    </mat-drawer>

    <div class="sidenav-content">
        <button mat-button class="sidenav-toggle-open" (click)="drawer.toggle()">
            <img src="../../assets/images/icons/FillchevronRight.svg" [ngClass]="{ 'chevron-left': isDrawerOpen==false, 'chevron-right': isDrawerOpen==true }">
        </button>

        <div *ngIf="noDataValidation" class="noValidation">No Data Validation available</div>
        <mat-tab-group animationDuration="0ms" #tabs class="run-data-tables" *ngIf="hasData"  mat-stretch-tabs="false" dynamicHeight (focusChange)="selectDataValidationName($event)">
            <mat-tab *ngFor="let tablename of tableNames">
                <ng-template mat-tab-label>
                  {{tablename}}
                </ng-template >
                <ng-template class="tab-content" matTabContent>
                    <app-tabulator-table *ngIf="selectedDataValidationDate" [integrationName]="selectedDataValidationSolution" [tableName]="selectedDataValidationName" [processDate]="selectedDataValidationDate">
                    </app-tabulator-table>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-drawer-container>