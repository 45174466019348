import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DateListing } from 'src/app/models/date-listing';
import { DataLakeService } from 'src/app/services/data-lake.service';
import { DisplayService } from 'src/app/services/display.service';

@Component({
  selector: 'app-run-log',
  templateUrl: './run-log.component.html',
  styleUrls: ['./run-log.component.css']
})
export class RunLogComponent implements OnInit {
  public tablesLoading = false;
  integrationName: string;
  dates: DateListing[];
  date: string;
  log: string[] = [];
  showNoErrors: boolean = false;
  saveIconShow: boolean = true;
  hasData: boolean = false;
  public isDrawerOpen: boolean = false;
  @ViewChild('drawer', {static: false}) public drawer?: MatDrawer

  constructor(
    private dataLakeService: DataLakeService,
    private displayService: DisplayService) { 
    }

 ngOnInit() {
  this.displayService.$selectedRunLogDrawer.subscribe(open => {
    this.dataLakeService.$dataLakeRunData.subscribe(runData => {
      if(runData != null){
        var selectedSolutionName = this.displayService.$selectedRunLogSolution.value;
        this.integrationName = selectedSolutionName; 
        var selectedRunData = runData.solutionListings.find(s => s.name == selectedSolutionName);
        if(selectedRunData != null){
          this.dates = selectedRunData.dateListings;
            this.drawer?.open();
        }
      }
      else{
        this.dates = [];
          this.drawer?.open();
      }
    });

  });
  }

  // load & render a selected integration's run log
  async setData(date: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.date=date;
    this.tablesLoading = true
    this.hasData = false;
    console.log(this.integrationName)
    this.log = await (await this.dataLakeService.getLog(this.integrationName, date, "RunLog")).contents;
    if(this.log.length == 0){
      this.showNoErrors = true;
      this.saveIconShow = false;
      this.displayService.$selectedGrayOut.next(false);
    }else{
      this.showNoErrors = false;
      this.saveIconShow = true;
      this.displayService.$selectedGrayOut.next(false);
    }
    this.hasData = true;
    this.displayService.$selectedGrayOut.next(false);
  }

  setDataStudioTitle(title:string){
    this.displayService.setDataStudioTitle(title);
  }

  //Track when the mat-drawer is opened or closed
  onOpenedChange(event){
    this.isDrawerOpen = !this.isDrawerOpen;
  }  
    //downloads a table as json to the browser
    downloadJson(){
       this.dataLakeService
      .downloadLog(this.integrationName,this.date, "RunLog").then(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = `RunLog.json`;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
    }
  //   openDrawer(x){
  //     if(x == true){
  //     this.drawer?.open();

  //   }
  // }
}
